import { Component, Input, OnInit } from '@angular/core';
import { ContentfulService } from 'app/contentful.service';

@Component({
  selector: 'app-services-offered',
  templateUrl: './services-offered.component.html',
  styleUrls: ['./services-offered.component.scss']
})
export class ServicesOfferedComponent implements OnInit {

  @Input() services: any;  

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    console.log(this.services);
  }

  convertDriveLinks(urls: any){
    return this.contentfulService.convertDriveLinks(urls);
  }

  onImageError(image: any){
    return this.contentfulService.linkConverter(image);
  }

  afterImgLoad(image: any) {
    return image.isLoading = false;
  }

}
