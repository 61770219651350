import { Component, OnInit } from '@angular/core';
import { Title } from 'app/shared-title/shared-title.component';

@Component({
  selector: 'app-creation-timeline',
  templateUrl: './creation-timeline.component.html',
  styleUrls: ['./creation-timeline.component.scss']
})
export class CreationTimelineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  titleObj : Title  = {
    title: 'Our Creative Work Process',
    titleFontSize : 12,
    subtitle: 'Discover How Our Interior Design Expertise Brings Your Vision to Life',
    subtitleFontSize : 10
  }
}
