import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'app/contentful.service';
import { SharedHeader } from 'app/custom/shared-header';
import { ImageDataService } from 'app/image-data.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  designService: any;
  turnkeyService: any;

  sharedHeader: SharedHeader = {
    title: "Services",
    subtitle: "Please review our services",
    images: ["https://images.squarespace-cdn.com/content/v1/5dd86eda07db674ed859c949/1586052700492-M6FRD5SRGOOA4DYCI5C6/deVol-kitchen-how-design-room-guide.jpg?format=1000w"]
  }

  constructor(private imageDataService: ImageDataService, private contentfulService: ContentfulService) {
    this.designService = {
      subTitle: "Design Consultation",
      description: "Our design consulting services provide clients with expert advice on a wide range of interior design topics, including space planning, furniture selection, color palettes, and more.",
      items : [
        {
          img: [],
          title: "Residential Interior Design",
          description: "Our residential interior design services cater to homeowners looking to enhance the functionality, style, and comfort of their living spaces. Our team works closely with clients to understand their needs, preferences, and budget to create beautiful and functional interiors that reflect their unique personality."
        },
        {
          img: [],
          title: "Commercial Interior Design",
          description: "We offer a comprehensive range of commercial interior design services, from space planning to custom furniture design. Our team works with business owners, architects, Interior Designers, and contractors to create commercial spaces that are inviting, functional, and aligned with their brand identity."
        }
      ]
    } 
    this.turnkeyService = {
      subTitle: "Turnkey Services",
      description: "We handle every aspect of the project, resulting in a fully furnished and functional space for the client. It's a stress-free and seamless option for those who want to renovate or design their spaces",
      items : [
        {
          img: [],
          title: "Residential Turnkey Services",
          description: "Transform your dream home into a reality with our turnkey residential project services. At our interior design firm, we understand that a renovation or interior design project can be overwhelming, which is why we offer a complete and hassle-free solution. Our talented design team will work with you every step of the way to develop a concept that aligns with your vision and then handle every aspect of the project, from budgeting to installation. With our turnkey services, you can sit back and relax while we bring your dream home to life, creating a space that reflects your style and personality while being fully functional and comfortable. Let us handle the details while you enjoy the transformation of your living space."
        },
        {
          img: [],
          title: "Commercial Turnkey Services",
          description: "Transform your commercial space into a stunning and functional work environment with our turnkey commercial project services. At our interior design firm, we specialize in creating workspaces that promote productivity and creativity while reflecting your brand and company culture. Our experienced design team will handle every aspect of the project, from developing a concept that aligns with your vision to managing the installation process. We understand that time is of the essence when it comes to commercial projects, which is why we work diligently to ensure that your project is completed on time and within budget. With our turnkey services, you can focus on running your business while we handle the details of your commercial interior design project. Contact us today to learn more about how we can help transform your workspace into a beautiful and functional environment that your employees and customers will love."
        }
      ]
    } 
   }

  ngOnInit(): void {

    this.imageDataService.getData().subscribe(x => {
      this.sharedHeader.images = x['fields'].services;
      this.designService.items[0].img = this.convertDriveLinks(x['fields'].residentialInteriorDesign);
      this.designService.items[1].img = this.convertDriveLinks(x['fields'].commercialInteriorDesign);
      this.turnkeyService.items[0].img = this.convertDriveLinks(x['fields'].turnkeyResidential);
      this.turnkeyService.items[1].img = this.convertDriveLinks(x['fields'].turnkeyCommercial);
    });

     
  }

  convertDriveLinks(urls: any){
    return this.contentfulService.convertDriveLinks(urls);
  }

}
