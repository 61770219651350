import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioSingleComponent } from './portfolio-single.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    PortfolioSingleComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    
  ],exports:[
    PortfolioSingleComponent,
  ]
})
export class PortfolioSingleModule { }
