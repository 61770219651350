import { Component, OnInit } from '@angular/core';
import { SharedHeader } from 'app/custom/shared-header';
import { ImageDataService } from 'app/image-data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  sharedHeader: SharedHeader = {
    title: "Contact",
    subtitle: "How to contact us",
    images: ["https://images.squarespace-cdn.com/content/v1/5dd86eda07db674ed859c949/1586052700492-M6FRD5SRGOOA4DYCI5C6/deVol-kitchen-how-design-room-guide.jpg?format=1000w"]
  }

  contactItems: any = [
    { icon: 'fa fa-map-marker', content: '501 Plot No - 13, Shah Prima, Sector 2, Kharghar, Navi Mumbai, Maharashtra 410210' },
    { icon: 'fa fa-calendar', content: '9 am - 6 pm' },
    { icon: 'fa fa-envelope', content: 'skycreation07@gmail.com' },
    { icon: 'fa fa-phone', content: '092217 86797' }]

  constructor(private imageDataService: ImageDataService) { }

  ngOnInit(): void {
    this.imageDataService.getData().subscribe(x => {
      this.sharedHeader.images = x['fields'].contact;
    });
    this.scrollWindow();
  }

  
  scrollWindow() {
    window.scroll({
      top: 650,
      left: 0,
      behavior: 'smooth'
    });
  }
}
