import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultEmailComponent } from './consult-email.component';
import { SharedTitleModule } from 'app/shared-title/shared-title.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'app/cookie.service';

@NgModule({
  declarations: [ConsultEmailComponent],
  imports: [
    CommonModule,
    SharedTitleModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule
  ],
  providers:[CookieService],
  exports: [ConsultEmailComponent]
})
export class ConsultEmailModule { }
