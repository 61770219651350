import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from 'app/contentful.service';
import { SharedHeader } from 'app/custom/shared-header';
import { ImageDataService } from 'app/image-data.service';

@Component({
  selector: 'app-portfolio-gallery',
  templateUrl: './portfolio-gallery.component.html',
  styleUrls: ['./portfolio-gallery.component.scss']
})
export class PortfolioGalleryComponent implements OnInit {
  selectedValue: any;
  // matTabLabels = ['ALL', 'LA', 'SF'];
  matTabLabels = null;
  sharedHeader: SharedHeader = {
    title: "Portfolio",
    subtitle: "Our work cases",
    images: ["https://images.squarespace-cdn.com/content/v1/5dd86eda07db674ed859c949/1586052700492-M6FRD5SRGOOA4DYCI5C6/deVol-kitchen-how-design-room-guide.jpg?format=1000w"]
  }
  
  posts$ = this.contentfulService.getPortfolio();
  projects: any;
  constructor(private contentfulService: ContentfulService, private router: Router,private imageDataService: ImageDataService) { }

  ngOnInit(): void {
    this.imageDataService.getData().subscribe(x => {
      this.sharedHeader.images = x['fields'].portfolio;
    });
    this.posts$.subscribe(resp => {
      if (resp) {
        this.projects = resp;
        console.log(this.projects)
      }
    })
  }

  // tabChanged(event: any) {
  //   if (event.index != 0) {
  //     const filterText = event.tab.textLabel;
  //     this.selectedValue = this.Users.users.filter((user: any) => {
  //       return user.city === filterText;
  //     });
  //   } else {
  //     this.selectedValue = this.Users.users;
  //   }
  // }
  navigateToProject(item) {
    this.router.navigate(['/portfolio/'+item.sys.id]);
  }

  afterImgLoad(image: any) {
    return image.isLoading = false;
  }

  convertDriveLinks(urls : any){
    return this.contentfulService.linkConverter(urls[0]).url;
  }

}
