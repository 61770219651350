import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  items = ["2D/ 3D Design Layout","Civil Space Planning","Residential Interior","Master Bathroom","Modular Kitchen","Office Interior"]

  socialIcons = [
    {icon:"/assets/img/icons/social/fb.svg", link:"https://www.facebook.com/7creationsky/"},
    {icon:"/assets/img/icons/social/insta.svg",link:"https://www.instagram.com/7skycreation/"},
    {icon:"/assets/img/icons/social/lin.svg",link:"https://www.linkedin.com/company/sky-creation/"},
    // {icon:"/assets/img/icons/social/twit.svg",link:"https://twitter.com"},
    {icon:"/assets/img/icons/social/wa.svg",link:"https://api.whatsapp.com/send?phone=9221786797"},
    {icon:"/assets/img/icons/social/yt.svg",link:"https://www.youtube.com/channel/UCaNQFdY3lb7I0UB6Qr5S5vg"}
  ];

  constructor() { }

  ngOnInit(): void {

  }

  rerouteTo(url){
    window.open(url, '_blank').focus();
  }

}
