import { Component, Input, OnInit } from '@angular/core';
import { Timeline } from 'app/custom/timeline';

@Component({
  selector: 'app-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.scss']
})
export class VerticalTimelineComponent implements OnInit {
  
  @Input() timeline: Timeline;

  constructor() { }

  ngOnInit(): void {
  }

}
