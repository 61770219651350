import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from 'app/contentful.service';
import { ImageDataService } from 'app/image-data.service';
import { Title } from 'app/shared-title/shared-title.component';
import { History } from '../../custom/history';

@Component({
  selector: 'app-history-section',
  templateUrl: './history-section.component.html',
  styleUrls: ['./history-section.component.scss']
})
export class HistorySectionComponent implements OnInit {
  history: History = {
    subtitle: "History of our creation",
    description: "Our successful interior design company, led by our founder and Head Interior designer, Vivek Bhokare, has been making a mark in the industry for 10 years. With a focus on delivering exceptional design solutions, our team is committed to creating personalized and stunning spaces that enhance our clients' lifestyles. Our strength lies in providing excellent customer service, ensuring that every project is executed with precision, attention to detail, and timely delivery. We take pride in our ability to deliver innovative and unique designs that meet our clients' needs and exceed their expectations. With Vivek Bhokare's expertise and passion for interior design, combined with our team's commitment to service and quality, we are confident in our ability to continue providing amazing designs and transforming spaces for many years to come.",
    firstImageUrl: "https://evolveindia.co/wp-content/uploads/2021/07/1_The-Wooden-Rhapsody-Modern-Bedroom-Interior-Design.jpg",
    //secondImageUrl: "https://img.staticmb.com/mbcontent/images/uploads/2021/2/striking-pendant-lights.jpg",
    secondImageUrl: null,
    videoUrl: null,
    videoTitle: "Watch a video about how we work and try for our customers"
  };
  historyImages: any = [];
  titleObj : Title  = {
    title: 'History of our creation',
    titleFontSize : 12,
    subtitle: 'Design Legacy that Shaped Us',
    subtitleFontSize : 10
  }
  constructor(private router: Router, private contentfulService: ContentfulService, private imageData: ImageDataService) {
    window.addEventListener("scroll", (event) => {
      var reveals = document.querySelectorAll(".reveal");
     // var brandlogo = document.getElementsByClassName("brand-logo");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 200;
    
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
         } 
      }
    });
   }

  ngOnInit(): void {
    this.imageData.getData().subscribe(x => {
      this.historyImages = this.convertDriveLinks(x['fields'].history);
    });
    // this.history = 'History of our creation';
  }
  routeTo(route){
    console.log(route);
    this.router.navigate([route]);
  }

  convertDriveLinks(urls: any){
    return this.contentfulService.convertDriveLinks(urls);
  }

  onImageError(){
    this.convertDriveLinks(this.historyImages);
  }

  afterImgLoad(image: any) {
    return image.isLoading = false;
  }

}
