import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { PortfolioSingleComponent } from './portfolio-single/portfolio-single.component';
import { PortfolioGalleryComponent } from './portfolio-gallery/portfolio-gallery.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    { path: 'aboutus',          component: AboutUsComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'portfolio/:id',    component: PortfolioSingleComponent },
    { path: 'contact',          component: ContactComponent },
    { path: 'services',         component: ServicesComponent },
    { path: 'gallery',          component: PortfolioGalleryComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
