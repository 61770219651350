import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from 'app/contentful.service';
import { ImageDataService } from 'app/image-data.service';
import { Basicelement } from './basicelement';

@Component({
  selector: 'app-basicelements',
  templateUrl: './basicelements.component.html',
  styleUrls: ['./basicelements.component.scss'],
})
export class BasicelementsComponent implements OnInit {
    simpleSlider = 40;
    doubleSlider = [20, 60];
    state_default: boolean = true;
    focus: any;
    basicelement: Basicelement = {
      landingPageText: "We Make Your Home Better",
      landingPageSubtitleText: "Bringing your vision to life with inspired interior design solutions",
      landingPageImageArray: [
        {imageUrl: '/assets/img/flower-pot.jpg', imageTitle: 'flower-pot'},
        {imageUrl: '/assets/img/flower-pot2.jpg', imageTitle: 'flower-pot2'},
        {imageUrl: '/assets/img/flower-pot3.jpg', imageTitle: 'flower-pot3'}
      ]
    };
    homeImages$ = this.contentfulService.getWebsiteImages();

    constructor(private contentfulService: ContentfulService,private router: Router,private imageData: ImageDataService) {
     
      window.addEventListener("scroll", (event) => {
        var reveals = document.querySelectorAll(".reveal");
       // var brandlogo = document.getElementsByClassName("brand-logo");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 200;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
           } 
        }
      });
     }

    ngOnInit() {
      this.imageData.getData().subscribe(resp => 
        {
          if(resp){ 
            this.basicelement.landingPageImageArray 
              = this.contentfulService.convertDriveLinks(resp['fields'].home);
          }
        })
      
    }

    redirectTo(){
      this.router.navigate(['/contact']);
    }

}
