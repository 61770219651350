import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-title',
  templateUrl: './shared-title.component.html',
  styleUrls: ['./shared-title.component.scss']
})
export class SharedTitleComponent implements OnInit {

  @Input()
  titleObject : Title;

  constructor() { }

  ngOnInit(): void {
  }

}

export class Title{
  title?: string;
  titleFontSize?: number;
  subtitle?: string;
  subtitleFontSize?: number;
}
