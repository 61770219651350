import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { ContactSuggestionComponent } from './contact-suggestion/contact-suggestion.component';
import { ConsultEmailModule } from 'app/consult-email/consult-email.module';
import { CookieService } from 'ngx-cookie-service';
import { SharedHeaderModule } from 'app/shared-header/shared-header.module';

@NgModule({
  declarations: [
    ContactComponent,
    ContactSuggestionComponent],
  imports: [
    CommonModule,
    ConsultEmailModule,
    SharedHeaderModule
  ],
  exports:[ContactComponent]
})
export class ContactModule { }
