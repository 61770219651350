import { Component, OnInit } from '@angular/core';
import { Title } from 'app/shared-title/shared-title.component';

@Component({
  selector: 'app-about-us-members',
  templateUrl: './about-us-members.component.html',
  styleUrls: ['./about-us-members.component.scss']
})
export class AboutUsMembersComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  titleObj : Title  = {
    title: 'Redefining Designer Services for All',
    titleFontSize : 12,
    subtitle: 'Transforming Spaces with Exceptional Design Expertise',
    subtitleFontSize : 10
  }
}
