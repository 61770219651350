import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor(public location: Location, private element : ElementRef, private router: Router, private renderer : Renderer2) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        const scrolledNavBar = navbar.children[0].children[0].children[0].children[1].children[0].children[0].children[0];
        const mainLogo = this.element.nativeElement.getElementsByClassName('logo')[0];
        const mainLogoChildren = mainLogo.children;
        const tabs = this.element.nativeElement.getElementsByClassName('tabs')[0].children[0];
        // const burger = this.element.nativeElement.getElementsByClassName('burger')[0];
        tabs.classList.remove('scrolled-nav-tabs');
        // burger.classList.add('scrolled-nav-tabs');
        mainLogo.classList.remove('hide-element');
        for (let index = 0; index < mainLogoChildren.length; index++) {
           
            mainLogoChildren[index].classList.remove('hide-element');
        }
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;
            const offset = 100;
            if (number > offset || window.pageYOffset > offset) {
                // add logic
                scrolledNavBar.classList.remove('hide-element');
                tabs.classList.add('scrolled-nav-tabs');
                // burger.classList.remove('scrolled-nav-tabs');
                mainLogo.classList.add('hide-element');
                for (let index = 0; index < mainLogoChildren.length; index++) {
                    mainLogoChildren[index].classList.add('hide-element');
                }
            } else {
                // remove logic
                scrolledNavBar.classList.add('hide-element');
                tabs.classList.remove('scrolled-nav-tabs');
                // burger.classList.add('scrolled-nav-tabs');
                mainLogo.classList.remove('hide-element');
                for (let index = 0; index < mainLogoChildren.length; index++) {
                    mainLogoChildren[index].classList.remove('hide-element');
                }
            }
        });
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    routeTo(route:string){
        this.router.navigate(["/"+route]);
    }
    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}
