import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesOfferedComponent } from './services-offered/services-offered.component';
import { ServicesProcessComponent } from './services-process/services-process.component';
import { ServicesComponent } from './services.component';
import { VerticalTimelineModule } from 'app/vertical-timeline/vertical-timeline.module';
import { ConsultEmailModule } from 'app/consult-email/consult-email.module';
import { SharedHeaderModule } from 'app/shared-header/shared-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ServicesComponent,
    ServicesOfferedComponent,
    ServicesProcessComponent,
  ],
  imports: [
    CommonModule,
    VerticalTimelineModule,
    ConsultEmailModule,
    SharedHeaderModule,
    NgbModule
  ],exports:[
    ServicesComponent
  ]
})
export class ServicesModule { }
