import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsComponent } from './testimonials.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTitleModule } from 'app/shared-title/shared-title.module';



@NgModule({
  declarations: [TestimonialsComponent],
  imports: [CommonModule, NgbModule,SharedTitleModule], 
  exports: [TestimonialsComponent]
})
export class TestimonialsModule { }
