import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Testimonial } from 'app/custom/testimonial';
import { Title } from 'app/shared-title/shared-title.component';
import * as $ from 'jquery'
import { Basicelement } from '../components/basicelements/basicelement';
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  testimonials: Testimonial[] = [{
    name: 'Gurpreet Singh', company: '', portrait: "https://lh3.googleusercontent.com/a/AAcHTtfO3vv3-3s-_vZ5pyWM9LjCL1C24Ubfa_UOIHQy=s40-c-rp-mo-br100",
    description: "Mr. Vivek is extremely professional and prompt in taking up any interior project and seeing to it that the client is completely satisfied.\nHe has done my home interior before and now he is doing up my office interior as well.\n\nHe will always attend your calls.\nWill give you multiple interior designs to choose from.\nVery professional guy.\nIf you want to do your home or office interior, look no further.\nChoose Sky design", stars: 5
  },
  {
    name: 'Vimlesh Raturi', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMQ2LnL4V_Ft-_9c5tKNonq2C6-OgOSpuOS6M6P97cQ=s40-c-rp-mo-ba3-br100",
    description: "Very professional work. For the person who has very less knowledge about decor and colour combination, he is the  good advisor and he implemented what he promised.", stars: 5
  },
  {
    name: 'priya sriram', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMRw3u-AMExiZA5foPMHmPZtTzfF_BLmOfTFcLzvNkY=s40-c-rp-mo-br100",
    description: "Wonderful job by Vivek and team … house he designed for us is and simply fab … hats off for good job", stars: 5
  },
  {
    name: 'Rahul G', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMSvI9tslEuQwmYAfdFNuBqd9zwm7S62f_mHzqldgQ=s40-c-rp-mo-br100",
    description: "Attention to detail’s is one word to describe the experience.\nTeam has always given multiple options basis our budget and also ensured timely delivery", stars: 5
  },
  {
    name: 'Devmanus mantra', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMTLiNwYKWV4NKjH_1eDIvE-pyE833Iru4z6ff9W=s40-c-rp-mo-ba3-br100",
    description: "Vivek is good designer, he is provide always good quality of work as per our dream. You can trust him.", stars: 5
  },
  {
    name: 'Sushil Wasekar', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMSaSLtAuJhbq7cCGc23esNl8-fHWswoKUc7PUFZ4g=s40-c-rp-mo-ba2-br100",
    description: "One of the best interior designer with lots of patience and with best designs.", stars: 5
  },
  {
    name: 'Ravi Thakkar', company: '', portrait: "https://lh3.googleusercontent.com/a-/AD_cMMSRdqVQuKGOsivDUmrsBAAhZTJiZMC8Qh8Y9kfJDA=s40-c-rp-mo-ba4-br100",
    description: "Thank you Mr Vivek\nI like the way you make planning 👍\nThank you for the great design", stars: 5
  },
  ]

  titleObj : Title  = {
    title: 'Thanks for your sweet words to us',
    titleFontSize : 12,
    subtitle: 'Check out our google reviews',
    subtitleFontSize : 10
  }
  
  gamesFormatted: any[];
  games: any;
  mobile: boolean;
  constructor(private element: ElementRef) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
    this.carouselButtonChanges();
  }

  resize() {
    if (window.screen.width < 1050) { // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
  ngOnInit(): void {
    this.resize();
    this.games = this.testimonials
    this.games = this.games.map(item => ({
      ...item, showMore: false
    }));
    this.gamesFormatted = [];
    var j = -1;

    for (var i = 0; i < this.games.length; i++) {
      if (i % 4 == 0) {
        j++;
        this.gamesFormatted[j] = [];
        this.gamesFormatted[j].push(this.games[i]);
      }
      else {
        this.gamesFormatted[j].push(this.games[i]);
      }
    }
  }

  trimString(text, length) {
    return text.length > length ? 
           text.substring(0, length) + '...' :
           text;
  } 

  ngAfterViewInit() {
    this.carouselButtonChanges();
  }

  carouselButtonChanges() {
    const navbar: HTMLElement = this.element.nativeElement;
    navbar.getElementsByClassName('carousel-item')[0].classList.add("testimonial-carousel");
    const indicators = navbar.getElementsByClassName('carousel-indicators')[0];
    indicators.setAttribute("style", "bottom: 5em;");
    for (let index = 0; index < indicators.children.length; index++) {
      indicators.children[index].setAttribute("style", "border: 1px solid gray;");
    }
    const width = window.screen.width*(0.7);
    console.log(width);
    const controlprev = navbar.getElementsByClassName('carousel-control-prev')[0];
    controlprev.setAttribute("style", "background-color: #f5593d;;color: black;top: 81.2%;; opacity:1; border-radius: 10%;height: 50px;width: 80px;left: 2vw;");
    const controlnext = navbar.getElementsByClassName('carousel-control-next')[0];
    controlnext.setAttribute("style", "background-color: #f5593d;;color: black;top: 81.2%;float:right; opacity:1;right: 0;opacity:1; border-radius: 10%;height: 50px;width: 80px;");

  }

}
