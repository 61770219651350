import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'app/cookie.service';
import { Title } from 'app/shared-title/shared-title.component';

@Component({
  selector: 'app-consult-email',
  templateUrl: './consult-email.component.html',
  styleUrls: ['./consult-email.component.scss']
})
export class ConsultEmailComponent implements OnInit {

  consultImage = "https://media.glamour.com/photos/6026ac6cfd2c52217f9dd410/master/w_1600%2Cc_limit/StylingSetDesign_JulietteWanty_Photography_WendyFenwick_HomestyleMagazine.jpg";

  consultEmailItems: any = [
    {name: 'Email', type:'text', key: 'email', subtext:'We\'ll never share your email with anyone else.', error:'Please enter email'},
    {name: 'Phone Number', type:'text', key: 'phoneNum', subtext: null, error:'Please enter email'},
    {name: 'First Name', type:'text', key: 'firstName', subtext: null, error:'Please enter your First Name'},
    {name: 'Last Name', type:'text', key: 'lastName', subtext: null, error:'Please enter your Last Name'},
    {name: 'Message', type:'text', key: 'message', subtext: null, error:'Please enter a Message'}
  ]

  titleObj : Title  = {
    title: 'Get a free consultation',
    titleFontSize : 12,
    subtitle: 'leave a request below',
    subtitleFontSize : 10
  }
  thanksObj : Title  = {
    title: 'We appreciate your inquiry',
    titleFontSize : 12,
    subtitle: 'You can expect to hear back from us soon.',
    subtitleFontSize : 10
  }
  title = 'newMat';
  isLinear = true;
  
  consultEmailGroup: FormGroup;
  submittedSuccessfully: boolean = false;
  isLoading: boolean = false;
  cookieVal: any;
  constructor(private _formBuilder: FormBuilder, private http: HttpClient,
    private elementRef: ElementRef, private renderer: Renderer2, public cookie: CookieService) { }

  ngOnInit(): void {
    this.cookieVal = this.cookie.getCookie('submittedSuccessfully');
    this.submittedSuccessfully = this.cookieVal === 'true' ? true : false;
    if(this.submittedSuccessfully){
      this.transformBackground();
    }
    this.consultEmailGroup = this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      contactNum: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$")]),
      email: new FormControl('', Validators.required),
      serviceType: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
    });
  }

  onInput(value: any, input: any): void {
    const nameRegex = /^[A-Za-z]+$/;
    const phoneNumberRegex = /^[0-9+\-\(\)\s]*$/;
    if (value && !nameRegex.test(value) && (input == 'firstName' || input == 'lastName')) {
      this.consultEmailGroup.controls[input].setValue(value.replace(/[^A-Za-z]+$/, ''));
    }

    if (value && !phoneNumberRegex.test(value) && input == 'contactNum') {
      this.consultEmailGroup.controls[input].setValue(value.replace(/[^0-9+\-\(\)\s]+$/, ''));
    }

  }

  transformBackground(){
    const parentElement = this.elementRef.nativeElement.querySelector('.marble');
    for (let index = 1; index <= 3; index++) {
      const childDiv = this.renderer.createElement('div');
      this.renderer.addClass(childDiv, 'star'+index);
      this.renderer.appendChild(parentElement, childDiv);
    }
    this.renderer.removeClass(parentElement, 'marble');
    this.renderer.addClass(parentElement, 'blue-bg');
  }

  submit() {
    const val = this.consultEmailGroup.value;
    if (this.consultEmailGroup.valid) {
      this.isLoading = true;
      const formData = new FormData();
      const headers = new HttpHeaders({ "Content-Type": "application/json" , "Accept":"application/json"});
  
      this.http.post("https://formsubmit.co/ajax/skycreation07@gmail.com",JSON.stringify(val), {headers:headers}).subscribe(res => {
        console.log(res);
        this.submittedSuccessfully = true;
        this.transformBackground();
        this.cookie.setCookie({
          name: 'submittedSuccessfully',
          value: 'true',
          session: true,
        });
      },(error) => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    }

   

    // const headers = new HttpHeaders({ "Content-Type": "application/json" });
    // this.http.post(
    //   "https://formsubmit.co/siddharthvalluvan93@gmail.com",
    //   { "firstName": val.firstName, "lastName": val.lastName, 
    //   "contactNum": val.contactNum, "email": val.email, "message": val.message },
    //   { headers: headers }
    // )
    //   .subscribe(response => {
    //     console.log(response);
    // });
  }
}



