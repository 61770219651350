import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ContentfulService } from './contentful.service';

@Injectable({
  providedIn: 'root'
})
export class ImageDataService {
  // private nameSource = new BehaviorSubject<string>('');
  // name = this.nameSource.asObservable()
  constructor(private contentfulService: ContentfulService) { }
  // changeName(name: string) {
  //   this.nameSource.next(name);
  // }

  private readonly request: Observable<Object> = this.contentfulService.getWebsiteImages()
  .pipe(
    map((result: Object) => {
      return result
    }), 
    shareReplay(),
  );

  getData(): Observable<Object> {
    return this.request;
  }
}
