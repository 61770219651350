import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../carousel/carousel.component';
import { LatestProjects } from '../../custom/latest-projects';
import { Router } from '@angular/router';
import { ContentfulService } from 'app/contentful.service';
import { Title } from 'app/shared-title/shared-title.component';
@Component({
  selector: 'app-latest-projects',
  templateUrl: './latest-projects.component.html',
  styleUrls: ['./latest-projects.component.scss']
})
export class LatestProjectsComponent implements OnInit {
  @ViewChild("carousel") carousel: CarouselComponent;
  slideCounter: number = 0;
  latestProjects: LatestProjects = {
    subtitle: "Our latest projects",
    description: "From residential to commercial, we have had the privilege to work on various projects that reflect our clients' unique personalities and needs. Our team of talented designers and craftsmen work closely with each client, ensuring that every detail is considered, resulting in beautiful and personalized interiors. We believe that great design has the power to transform lives, and we are proud to share our portfolio that represents our commitment to excellence and innovation in interior design"
  }
  titleObj : Title  = {
    title: 'Our latest projects',
    titleFontSize : 12,
    subtitle: 'Designs that Reflect Your Style',
    subtitleFontSize : 10
  }
  postResponse: any;
  imgQuantity: number;
  posts$ = this.contentfulService.getPortfolio();
  constructor(private contentfulService: ContentfulService, private router: Router) {
    window.addEventListener("scroll", (event) => {
      var reveals = document.querySelectorAll(".reveal");
     // var brandlogo = document.getElementsByClassName("brand-logo");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 200;
    
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
         } 
      }
    });
  }


ngOnInit(): void {
  
  this.posts$.subscribe(resp => {
    if (resp) {
      this.postResponse = resp;
      this.imgQuantity = this.postResponse.length;
    }
  })
}

projectClick(project){
  this.router.navigate(['/gallery']);
}
prev(){
  this.getSlideNum(this.slideCounter - 1);
  this.carousel.setSlide(this.slideCounter);
}
next(){
  this.getSlideNum(this.slideCounter + 1);
  this.carousel.setSlide(this.slideCounter);
}
getSlideNum(num : number){
  if (num <= 0) {
    this.slideCounter = this.imgQuantity;
  } else if (num > this.imgQuantity) {
    this.slideCounter = 1;
  } else {
    this.slideCounter = num;
  }
}
changeSlideNum(event){
  console.log(event);
  this.slideCounter = event;
}
convertDriveLinks(urls : any){
  // for (let i = 0; i < images.length; i++) {
  //   const url = images[i];
    if(urls.length > 0){
      if (urls[0].includes('drive.google.com')) {
        if (!urls[0].includes('/uc?export=download&id=')) {
          const fileId = urls[0].match(/\/file\/d\/([^\/]*)/)[1];
          urls[0] = `https://drive.google.com/uc?export=download&id=${fileId}`;
        }
      }
    }
    return urls[0];
  // }
  // return images;
}

}
