import { Component, OnInit } from '@angular/core';
import { Timeline } from 'app/custom/timeline';

@Component({
  selector: 'app-services-process',
  templateUrl: './services-process.component.html',
  styleUrls: ['./services-process.component.scss']
})
export class ServicesProcessComponent implements OnInit {
  timeline1: Timeline = {
    num: "01.",
    title: "Architectural development",
    element: [
      {
        elementTitle: "Development of the object concept",
        elementContents: [
          "Planning decision",
          "Development of the object's style direction and its functional content",
          "Identification of important indicators of the technical and economic justification of the project"
        ]
      },
      {
        elementTitle: "Development of the object concept",
        elementContents: [
          "basic solutions for spatial planning",
          "stylistic and architectural design of the object"
        ]
      },
      {
        elementTitle: "Development of the object concept",
        elementContents: [
          "The General plan of the site",
          "Foundation design",
          "Structural plan of walls and floors",
          "Rafters and roof plan",
          "The project of engineering networks"
        ]
      },
      {
        elementTitle:"Development of the object concept"
      }
    ]
  }
  timeline2: Timeline = {
    num: "02.",
    title: "Interior design",
    element: [
      {
        elementTitle: "Planning solution development",
        elementContents: [
          "Measurings",
          "Draft design concept",
          "Object budgeting"
        ]
      },
      {
        elementTitle: "Development of the object concept",
        elementContents: [
          "Planning decision",
          "3D-visualization"
        ]
      },
      {
        elementTitle: "Development of the object concept",
        elementContents: [
          "Full package of working documentation",
          "Selection of materials",
          "Architectural supervision"
        ]
      }
    ]
  }
  timeline3: Timeline = {
    num: "03.",
    title: "Commercial interior design",
    element: [
      {
        elementTitle: "Dive into the project",
        elementContents: [
          "Meeting and discussion of the idea",
          "Measurements",
          "Concept development",
          "Object budgeting"
        ]
      },
      {
        elementTitle: "Design",
        elementContents: [
          "Planning decision",
          "3D visualization of premises"
        ]
      },
      {
        elementTitle: "Implementation",
        elementContents: [
          "Full package of working documentation",
          "Selection of face, finishing materials and furniture",
          "Architectural supervision"
        ]
      }
    ]
  }
  constructor() { }

  ngOnInit(): void {
  }

}
