import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { PortfolioSingleModule } from './portfolio-single/portfolio-single.module';
import { PortfolioGalleryModule } from './portfolio-gallery/portfolio-gallery.module';
import { AboutUsModule } from './about-us/about-us.module';
import { TestimonialsModule } from './testimonials/testimonials.module';
import { CarouselModule } from './carousel/carousel.module';
import { FooterModule } from './footer/footer.module';
import { ContactModule } from './contact/contact.module';
import { ServicesModule } from './services/services.module';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    PortfolioSingleModule,
    PortfolioGalleryModule,
    ContactModule,
    TestimonialsModule,
    ServicesModule,
    AboutUsModule,
    ExamplesModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CarouselModule,
    FooterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
