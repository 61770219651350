import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ContentfulService } from 'app/contentful.service';
import { PortfolioSingle } from 'app/custom/portfolio-single';
@Component({
  selector: 'app-portfolio-single',
  templateUrl: './portfolio-single.component.html',
  styleUrls: ['./portfolio-single.component.scss'],
})
export class PortfolioSingleComponent implements OnInit {

  portfolio: PortfolioSingle = {
    title: "History of our creation",
    description: ["Since our humble beginnings in 2006, We has significantly expanded its collection of exclusive lamps. Today we are proud to present you our best lighting design solutions. Our collection is updated every day with beautiful solutions and we are glad that you, our customers, support us on way! Our collection is updated every day with beautiful solutions", "Since our humble beginnings in 2006, We has significantly expanded its collection of exclusive lamps. Today we are proud to present you our best lighting design solutions. Our collection is updated every day with beautiful solutions and we are glad that you, our customers, support us on way! Our collection is updated every day with beautiful solutions"],
    images: ["https://media.architecturaldigest.com/photos/601063db77727eb141339c1f/master/w_1920%2Cc_limit/55204630_070_b.jpg",
      "https://i.pinimg.com/originals/23/93/ab/2393ab66416061e513c496974ad8be8c.jpg",
      "https://media.designcafe.com/wp-content/uploads/2019/12/17054728/pick-out-the-bed-wall-for-bedroom-design-2.jpg"],
    videoUrl: "",
    videoTitle: "Watch a video about how we work and try for our customers",
    additionalInfo: [{ key: "Object type", value: "Interior design" },
    { key: "Area", value: "2 690.978 ft2" },
    { key: "Term of works", value: " 6 months" },
    { key: "Budget", value: "$450 / ft2" }]
  };
  displayStyle = "none";
  constructor(private activatedRoute: ActivatedRoute, private contentfulService: ContentfulService, 
    private router: Router,private config: NgbCarouselConfig,private element: ElementRef) {
      config.interval = 5000;
      config.keyboard = true;
      config.pauseOnHover = true;
  }
  elem: HTMLElement = this.element.nativeElement;
  currentProject: any;
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.closePopup();
  }

  ngOnInit(): void {
    

    const posts$ = this.contentfulService.getPortfolio();
    this.activatedRoute.params.subscribe(params => {
      posts$.subscribe(items => {
        this.currentProject = items.find(x => x.sys.id == params['id']).fields;
        if (this.currentProject) {
          if (this.currentProject.description && this.currentProject.description.length > 0) {
            this.currentProject['description'] = this.splitParagraph(this.currentProject.description, '\n\n');
          }
          if (this.currentProject.additionalInfo && this.currentProject.additionalInfo.length > 0) {
            this.currentProject['additionalInfo'] = this.composeAdditionalInfo(this.splitParagraph(this.currentProject.additionalInfo, '\n'));
          }
          if (this.currentProject.images && this.currentProject.images.length > 0) {
            this.currentProject['images'] = this.convertDriveLinks(this.currentProject.images);
          }
        }

      });

    });
    this.scrollWindow();
  }

  convertDriveLinks(images: any) {
    return this.contentfulService.convertDriveLinks(images);
  }

  onImageError(){
    this.convertDriveLinks(this.currentProject.images);
  }

  afterImgLoad(image: any) {
    return image.isLoading = false;
  }

  splitParagraph(data: any, splitCharacter: any): any {    
    return data.split(splitCharacter);
  }

  composeAdditionalInfo(data: any){
    const values = [];
    data.forEach(element => {
      const val = {}
      let strings = element.split('=');
      val['key'] = strings[0].trim();
      val['value'] = strings[1].trim();
      values.push(val);
    });
    return values;
  }

  scrollWindow() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  routeTo(route) {
    this.router.navigate([route]);
  }

  openUrl(){
    window.open(this.currentProject.videoUrl);
  }

  openPopup() {
    const body = document.getElementsByTagName('body')[0];
    var navLoc = "";
    if(body.getBoundingClientRect().width <= 768){
      navLoc = "top: 101%";
    }
    body.classList.add('modal-open');
    const controlprev = this.elem.getElementsByClassName('carousel-control-prev')[0];
    controlprev.setAttribute("style", "background-color: #f5593d;;color: black; opacity:1; border-radius: 50%;height: 50px;width: 50px;"+navLoc);
    const controlnext = this.elem.getElementsByClassName('carousel-control-next')[0];
    controlnext.setAttribute("style", "background-color: #f5593d;;color: black;float:right; opacity:1;right: 0;opacity:1; border-radius: 50%;height: 50px;width: 50px;right: 1em;"+navLoc);
    this.displayStyle = "block";
  }

  closePopup() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
    this.displayStyle = "none";
  }
}