import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from 'app/contentful.service';
import { SharedHeader } from 'app/custom/shared-header';

@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss']
})
export class SharedHeaderComponent implements OnInit {

  @Input() sharedHeader: SharedHeader;

  constructor(private router: Router, private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    this.sharedHeader.images = this.contentfulService.convertDriveLinks(this.sharedHeader.images);
  }

  routeTo(route){
    this.router.navigate([route]);
  }

  convertDriveLinks(urls: any){
    return this.contentfulService.convertDriveLinks(urls);
  }

  onImageError(){
    this.convertDriveLinks(this.sharedHeader.images);
  }

  afterImgLoad(image: any) {
    return image.isLoading = false;
  }
}
