import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioGalleryComponent } from './portfolio-gallery.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedHeaderModule } from 'app/shared-header/shared-header.module';



@NgModule({
  declarations: [
    PortfolioGalleryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedHeaderModule
  ],exports:[
    PortfolioGalleryComponent
  ]
})
export class PortfolioGalleryModule { }
