import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedHeaderComponent } from './shared-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [SharedHeaderComponent],
  imports: [
    CommonModule,
    NgbModule
  ],exports:[SharedHeaderComponent]
})
export class SharedHeaderModule { }
