import { Injectable } from '@angular/core';
import { createClient } from 'contentful';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
   
  private CONFIG = {
    space: 'vdj4uigtiopq',
    accessToken: 'EHcCCwkX2-Gbl4asMNGR8IyzHW_hdRu6q18EK8A2zX0',
    contentTypeIds: {
      portfolio: 'project',
      websiteImages: 'websiteImages'
    }
  };

  

  CONTENTFUL_URL_PREFIX = 'https://cdn.contentful.com/spaces/'+this.CONFIG.space+'/environments/master/entries';

  private cdaClient = createClient({
    space: this.CONFIG.space,
    accessToken: this.CONFIG.accessToken
  });

  private httpClient: HttpClient;

  constructor(
    httpBackend: HttpBackend
    ) {
      this.httpClient = new HttpClient(httpBackend);
  }

  // getBasicElements(query?: object): any {
  //   return from(
  //     this.cdaClient.getEntries({
  //       ...Object,
  //       content_type: this.CONFIG.contentTypeIds.angularPost,
  //       query
  //     })
  //   ).pipe(map(posts => posts.items));
  // }

  getPortfolio(query?: object): any {
    return from(
      this.cdaClient.getEntries({
        ...Object,
        content_type: this.CONFIG.contentTypeIds.portfolio,
        query
      })
    ).pipe(map(posts => posts.items));
  }

  getSingleProject(item?: string): any {
    // curl -v 'https://cdn.contentful.com/spaces/oc3u7dt7mty5/entries?access_token=6cabb22c95d52aa7752fe70ae9b3271a1fc2decf7ae7d99ccd7dceba718980e6&content_type=3HjHXUYR3yyosUqAGmi8wu&fields.restaurantField.sys.id=2UmoQ8Bo4g4S82WmGiQIQE'
    // let headers = new HttpHeaders()
    //   .set('Authorization', 'Basic '+this.CONFIG.accessToken);
    let params = new HttpParams()
    .set("access_token",this.CONFIG.accessToken)
    .set("content_type",this.CONFIG.contentTypeIds.portfolio)
    .set("target_entry_id",item);
    return this.httpClient.get(this.CONTENTFUL_URL_PREFIX, { params: params }).pipe(tap(res => {
      console.log(res);
      return res;
    }));
  }

  getWebsiteImages(){
    // https://cdn.contentful.com/spaces/{space_id}/environments/{environment_id}/entries/{entry_id}?access_token={access_token}
    let params = new HttpParams()
    .set("access_token",this.CONFIG.accessToken)
    // .set("content_type", this.CONFIG.contentTypeIds.websiteImages)
    // .set("target_entry_id","6j3WEM2BSDM1j8O6er8Xp3");
    return this.httpClient.get(this.CONTENTFUL_URL_PREFIX+"/6j3WEM2BSDM1j8O6er8Xp3", { params: params })
    // .pipe(tap(res => {
    //   return res;
    // }));
  }

  getPost(id: string): any {
    return from(this.cdaClient.getEntry(id));
  }
  
  linkConverter(singleImageUrl: any){
    if(singleImageUrl && singleImageUrl.length > 0 && typeof singleImageUrl == "string"){
        if(singleImageUrl.includes('drive.google.com')) {
          const match = singleImageUrl.match(/[-\w]{25,}/);
          if (match) {
            singleImageUrl = {url: `https://drive.google.com/uc?export=download&id=${match}`, isLoading: true};
          }
      }else{
        singleImageUrl = {url: singleImageUrl, isLoading: true};
      }
    }
    return singleImageUrl;
  }

  convertDriveLinks(images: any) {
    for (let i = 0; i < images.length; i++) {
      images[i] = this.linkConverter(images[i]);
    }
    return images;
  }

  // convertDriveLinks(urls : any){
  //     if(urls && urls.length > 0){
  //       if (urls[0] && typeof urls[0] == "string" && urls[0].includes('drive.google.com')) {
  //         const match = urls[0].match(/[-\w]{25,}/);
  //         if (match) {
  //           urls[0] = {url: `https://drive.google.com/uc?export=download&id=${match}`, isLoading: true};
  //       }
  //       }
  //     }
  //     return urls[0].url;
  // }
}
