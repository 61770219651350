import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HistorySectionComponent } from './history-section/history-section.component';
import { LatestProjectsComponent } from './latest-projects/latest-projects.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestimonialsModule } from 'app/testimonials/testimonials.module';
import { CarouselModule } from 'app/carousel/carousel.module';
import { FooterModule } from 'app/footer/footer.module';
import { CreationTimelineComponent } from './creation-timeline/creation-timeline.component';
import { ConsultEmailModule } from 'app/consult-email/consult-email.module';
import { SharedTitleModule } from 'app/shared-title/shared-title.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        BrowserAnimationsModule,
        JwBootstrapSwitchNg2Module,
        TestimonialsModule,
        CarouselModule,
        ConsultEmailModule,
        FooterModule,
        SharedTitleModule
    ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        ComingSoonComponent,
        HistorySectionComponent,
        LatestProjectsComponent,
        CreationTimelineComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
    ],
    exports:[ ComponentsComponent ]
})
export class ComponentsModule { }
