import { Component, OnInit } from '@angular/core';
import { SharedHeader } from 'app/custom/shared-header';
import { ImageDataService } from 'app/image-data.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  sharedHeader: SharedHeader = {
    title: "About Us",
    subtitle: "Our history",
    images: ["https://images.squarespace-cdn.com/content/v1/5dd86eda07db674ed859c949/1586052700492-M6FRD5SRGOOA4DYCI5C6/deVol-kitchen-how-design-room-guide.jpg?format=1000w"]
  }
  constructor(private imageDataService: ImageDataService) { }

  ngOnInit(): void {
    this.imageDataService.getData().subscribe(x => {
      this.sharedHeader.images = x['fields'].aboutUs;
    });
  }

}
