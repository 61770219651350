import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us-header',
  templateUrl: './about-us-header.component.html',
  styleUrls: ['./about-us-header.component.scss']
})
export class AboutUsHeaderComponent implements OnInit {
  headerImg = "https://media.designcafe.com/wp-content/uploads/2022/07/08123110/cool-bedroom-ideas-for-your-home.jpg";
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  routeTo(route){
    this.router.navigate([route]);
  }

}
