import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { DuplicateDirective } from './duplicate.directive';



@NgModule({
  declarations: [CarouselComponent,DuplicateDirective],
  imports: [
    CommonModule
  ],exports:[CarouselComponent,DuplicateDirective]
})
export class CarouselModule { }
