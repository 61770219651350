import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsComponent } from './about-us.component';
import { TestimonialsModule } from 'app/testimonials/testimonials.module';
import { AboutUsHeaderComponent } from './about-us-header/about-us-header.component';
import { AboutUsMembersComponent } from './about-us-members/about-us-members.component';
import { SharedTitleModule } from 'app/shared-title/shared-title.module';
import { SharedHeaderModule } from 'app/shared-header/shared-header.module';

@NgModule({
  declarations: [AboutUsComponent, AboutUsHeaderComponent, AboutUsMembersComponent],
  imports: [CommonModule, TestimonialsModule,SharedTitleModule,SharedHeaderModule],
  exports: [AboutUsComponent]
})
export class AboutUsModule { }
